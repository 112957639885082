import merge from 'lodash.merge'

import {
  Currency,
  MoneyValue,
  Product,
  ProductContentValue,
  ProductPrice,
  ProductVariant,
  ProductImage,
} from '../generated/graphql'

type ProductWithVariant = Pick<Product, 'images'> & {
  variants: (Pick<ProductVariant, 'images' | 'sku' | 'choices' | 'inStock'> & {
    price?: {
      price: Partial<MoneyValue>
      rrp: Partial<MoneyValue>
    } | null
    vipPrice: Partial<MoneyValue>
    product: {
      images: ProductImage[]
    }
  })[]
}

const defaultPrice: ProductPrice = {
  price: {
    displayValue: '£0.00',
    amount: '0.00',
    currency: Currency.Gbp,
    scalarValue: 'GBP0.00',
    __typename: 'MoneyValue',
  },
  rrp: {
    displayValue: '£0.00',
    amount: '0.00',
    currency: Currency.Gbp,
    scalarValue: 'GBP0.00',
    __typename: 'MoneyValue',
  },
  __typename: 'ProductPrice',
}

export const mergeVariantProduct = (
  product: Product,
  variantProduct: ProductVariant,
) => {
  const { variants, ...productWithoutVariant } = product
  const mergedProduct = merge(productWithoutVariant, variantProduct)

  return {
    ...mergedProduct,
    images: !variantProduct.images.length
      ? product.images
      : variantProduct.images,
    __typename: 'Product',
  }
}

export const mergeDefaultVariantWithDefaults = (
  product: ProductWithVariant,
  variant: ProductWithVariant['variants'][0],
) => {
  const vipPrice = variant?.vipPrice
    ? {
        price: {
          ...variant?.vipPrice,
          currency: defaultPrice.price.currency,
          scalarValue: variant?.vipPrice?.scalarValue,
          __typename: defaultPrice.price.__typename,
        },
      }
    : null

  return variant
    ? {
        ...variant,
        images: !variant.images.length ? product.images : variant.images,
        price: vipPrice
          ? vipPrice
          : variant.price
          ? variant.price
          : defaultPrice,
      }
    : null
}

export const mergeVariantsWithDefaults = (product: ProductWithVariant) =>
  product.variants.map((variant) => {
    const masterProductImages =
      variant.product?.images && variant.product.images.length > 0
    const variantImages = variant.images && variant.images.length > 0

    const images = variantImages
      ? variant.images
      : masterProductImages
      ? variant.product?.images
      : product.images || []

    const vipPrice = variant?.vipPrice
      ? {
          price: {
            ...variant?.vipPrice,
            currency: variant?.price
              ? variant.price?.price?.currency
              : defaultPrice.price.currency,
            scalarValue: variant?.vipPrice?.scalarValue,
            __typename: defaultPrice.price.__typename,
          },
        }
      : null

    return {
      ...variant,
      images,
      price: vipPrice ? vipPrice : variant.price ? variant.price : defaultPrice,
    }
  })

type ContentType = Omit<ProductContentValue, 'key'> & { key?: 'string' }

export const getProductTags = (content: ContentType[], tagKeys?: string[]) =>
  content.reduce((accumulator: ContentType[], contentItem: ContentType) => {
    if (tagKeys) {
      if (
        contentItem.value &&
        contentItem.value.stringListValue &&
        contentItem.key &&
        tagKeys.includes(contentItem.key)
      ) {
        accumulator.push(...contentItem.value.stringListValue)
      }
    } else {
      if (contentItem.value && contentItem.value.stringListValue) {
        accumulator.push(...contentItem.value.stringListValue)
      }
    }
    return accumulator
  }, [])
